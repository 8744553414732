$darkBlack: #0e0e0e;
$nightWhite: #ebe5cc;
$borderWidth: 3px;
$red: #9f0d1c;
.site-content.chiba {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
}
.site-content.chiba.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  background-image: none;
  transition: background-color 0.3s;
  .text {
    a {
      color: white;
    }
  }
}
.chiba.dark {
  // img.nav-bar-logo {
  //   box-sizing: border-box;
  //   background: url(https://afag.imgix.net/harvest-moon/logo-dark-2.png?h=90) no-repeat;
  //   background-size: contain;
  //   width: 204px;
  //   height: 42px;
  //   margin-left: 5px;
  //   padding-left: 204px !important;
  // }
  .cart.minimized {
    color: $nightWhite;
    background: $darkBlack;
    // border-bottom: 2px solid $nightWhite;
    border-bottom: none;
  }
  a.page-link {
    // font-weight: 400;
    color: $nightWhite;
    // border: $borderWidth solid $darkBlack;
    // border-top: none;
    // border-bottom: none;
    &:hover, &.active {
      color: $darkBlack;
      background: white;
      // border: $borderWidth solid $red;
      // border-top: none;
      // border-bottom: none;
    }
  }
  .pages-nav {
    color: $nightWhite;
    background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
}

.chiba.dark {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50);
    // background-position: 0% 50%;

  }


  a.page-link {
    // color: $nightWhite;
    // border-bottom-color: $darkBlack;
    &:hover, &.active {
      // border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
