$red: rgb(255, 211, 78);

.ordering-page {
  background: url(https://afag.imgix.net/electric-lotus/hero-2.jpg?w=1200) no-repeat;
  background-size: contain;

  .header {
    padding-top: 80px;
    padding-bottom: 80px;
    @media screen and (max-width: 450px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    text-align: center;
    .text {
      display: inline-block;
      border: 4px solid $red;
      border-top: none;
      border-bottom: none;
      background: black;
      color: white;
      padding: 0.5em 0.4em;
    }
  }
  .ordering {
    background: white;
    width: 90%;
    padding: 0 0.4em;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 5px 10px #818480;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
}

.dark {
  .ordering {
    background: black;
  }
}
